import bugsnag from '@bugsnag/js';
import Fingerprint2 from 'fingerprintjs2';

export async function initBugsnag() {
    let fingerprint = '',
        components;

    global.bugsnagClient = bugsnag({
        apiKey: CONFIG.bugsnag,
        appVersion: CONFIG.version || undefined,
        collectUserIp: false,
        releaseStage: CONFIG.env,
        notifyReleaseStages: ['production', 'prod', 'stage'],
        logger: null,
        beforeSend(report) {
            report.user = { id: fingerprint };
        },
    });

    // as the IP is not collected, generate a reliable fingerprint to detect how many users have the same error.
    // but getting the fingerprint is async, so include bugsnag and create the fingerprint afterwards,
    // as it is better to have a report with wrong id than at least no report at all
    components = await Fingerprint2.getPromise({
        exclude: ['fonts', 'canvas', 'webgl', 'audio'], // exclude some tests for speed
    });

    if (!components.error) {
        let values = components.map(component => component.value).join();

        fingerprint = Fingerprint2.x64hash128(values, 31);
    }
}

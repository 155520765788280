// ////////////////////////////////////////////////////////////
// run bugsnag as early as possible
import { bugsnag } from './lib/application';
bugsnag();

// ////////////////////////////////////////////////////////////
// include needed polyfills
import * as polyfills from '@spinnwerk/polyfills';

polyfills.dom();
polyfills.picturefill();
polyfills.intersectionObserver();
polyfills.objectFit();

// ////////////////////////////////////////////////////////////
// async css loading
import 'fg-loadcss/dist/cssrelpreload';

// ////////////////////////////////////////////////////////////
// image lazy loading
import 'lazysizes';

// ////////////////////////////////////////////////////////////
// check if cookies are enabled
// - add the .no-cookies class to <html /> if not
let cookieEnabled = Boolean(navigator.cookieEnabled);

if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') != -1;
}

if (!cookieEnabled) {
    document.documentElement.classList.add('no-cookies');
}
